$(document).ready(function(){
  
  $(".navipunkt.suchen a").on("click", function() {
    $("body").addClass("suchen");
    $(".suchfeld input.s").focus();

    $(".suchfeld input, .suchfeld button, .suchfeld a").attr("tabindex", "0");
    $(".navi a").attr("tabindex", "-1");

    return false;
  });
  
  $(".suchfeld  button.abbrechen").on("click", function() {
    $("body").removeClass("suchen");
    $(".suchfeld input.s").blur();

    $(".suchfeld input, .suchfeld button, .suchfeld a").attr("tabindex", "-1");
    $(".navi a").attr("tabindex", "0");

    return false;
  });

  var $slider = $('.slider').not(".p1").flickity({
    pageDots: false,
    wrapAround: true,
    prevNextButtons: true,
    imagesLoaded: true,
    setGallerySize: false,
    arrowShape: {
      x0: 10,
      x1: 40, y1: 50,
      x2: 48, y2: 50,
      x3: 18
    },
    // autoPlay: 8000,
    // pauseAutoPlayOnHover: true
  });
  
  var $overlay = $('.overlay');
  var flkty = $slider.data('flickity');
  
  $slider.on('select.flickity', function() {
    if ($(flkty.selectedElement).find("figcaption").length) {
      $overlay.html( $(flkty.selectedElement).find("figcaption").html() );
      $overlay.show();
    }
    else {
      $overlay.hide();
    }
  });
  
  $("header .hamburger").click(function() {
    $("body").toggleClass("naviopen");

    if($("body").hasClass("naviopen")) {
      $(".navi a").attr("tabindex", "0");
    }
    else {
      $(".navi a").attr("tabindex", "-1");
    }

  });
  $(".line.nav .hamburger").click(function() {
    $("body").toggleClass("subnavopen");

  });
  
  var $pswp = $('.pswp')[0];
  var image = [];

  $('.bilder').not(".pdfs").each( function() {
      var $bilder = $(this),
      getItems = function() {
          var items = [];
          $bilder.find("a.popup").each(function() {
              var $href   = $(this).attr('href'),
                  $size   = $(this).data('size').split('x'),
                  $width  = $size[0],
                  $height = $size[1],
                  $thumb  = $(this).find("img").attr("src");
              var item = {
                  src  : $href,
                  w    : $width,
                  h    : $height,
                  msrc : $thumb,
                  el  : $(this)
              }
              if ($.trim(item.el.find("figcaption").html()) != '') {
                item.title = item.el.find("figcaption").html()
              }
              items.push(item);
          });
          return items;
      }

      var items = getItems();

      $.each(items, function(index, value) {
          image[index]     = new Image();
          image[index].src = value['src'];
      });
      

      $bilder.on('click', '.popup', function(event) {
          
          event.preventDefault();
          
          var $index = $(this).index();
          var options = {
              index: $index,
              bgOpacity: 0.75,
              showHideOpacity: true,
              fullscreenEl: false,
              zoomEl: false,
              shareEl: false,
              history: false,
              getThumbBoundsFn: function($index) {
                var items = getItems();
                var thumbnail = items[$index].el.find('img')[0], // find thumbnail
                pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                rect = thumbnail.getBoundingClientRect(); 

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
              }
          }

          var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
          lightBox.init();
      });
  });
  
  function scroll() {
    var body = $("body").outerHeight();
    var header = $("header").outerHeight() + 10 + $("section.intro").outerHeight();
    var sidebar = $(".sidebar").outerHeight();
    var main = $("main").outerHeight();
    var footer = $("footer").outerHeight();
    var visibleFooter = ( (body - footer) - ( $(window).scrollTop() + $(window).height()) ) * -1;
    var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    
    if ($("body").css('border-top-style') == 'solid') {
      if(bodyScrollTop > header && sidebar <  main) {
       $("body").addClass("scrolled");
      }
      else {
       $("body").removeClass("scrolled");
      }
      if (sidebar > $(window).height() - visibleFooter && sidebar <  main) {
        $("body").addClass("toofarscrolled");
      }
      else {
      	$("body").removeClass("toofarscrolled");
      }
      
      if(bodyScrollTop > header - 100) {
       $("body").addClass("almostscrolled");
      }
      else {
       $("body").removeClass("almostscrolled");
      }
    }
    
    else {
      if(bodyScrollTop > header - 10) {
      	$("body").addClass("almostscrolled");
      }
      else {
        $("body").removeClass("almostscrolled");
      }
    }
    
  }
  
  function scrollactive() {
    var scrollPos = $(document).scrollTop();
    $('.line.nav a').not(".b2t").not(".filter a").each(function () {
      var currLink = $(this);
      var refElement = $(currLink.attr("href")).closest("section");

      if ((refElement.offset().top - 51) <= scrollPos &&
      refElement.offset().top - 51 + refElement.outerHeight() > scrollPos) {
        $('.line.nav a').removeClass("active");
        currLink.addClass("aktiv");
      }
      else if (currLink.hasClass("first") && refElement.offset().top - 51 + refElement.outerHeight() > scrollPos) {
        $('.line.nav a').removeClass("active");
        currLink.addClass("aktiv");
      }
      else{
        currLink.removeClass("aktiv");
      }
    });
  }
  
  $(window).on("scroll load resize", function() {

    if($(window).width() <= 1000) {
      $(".navi a").attr("tabindex", "-1");
    }
    else {
      if(!$('body').hasClass('suchen') || $('body').hasClass('naviopen')) {
        $(".navi a").attr("tabindex", "0");
      }
    }

    scroll();
    scrollactive();
  });
  
  $('.line.nav a').not(".line.filter a").not(".b2t").on("click", function() {
    offsetTop = $($(this).attr("href")).offset().top;
    $("body, html").animate({
      scrollTop: offsetTop
    }, 500);
    $("body").removeClass("subnavopen");
    history.pushState(null, null, $(this).attr("href"));
    return false;
  });
  
  $(".b2t").on("click", function() {
  	$("body, html").animate({
  	  scrollTop: 0
  	}, 500);
  	history.pushState(null, null, null);
  	return false;
  });
  
  function filter(tag) {
  	var activeFilter = $('.line.filter a[data-tag="' + tag + '"]');
  	if (tag == "all") {
  	  $("main.news section").removeClass("gefiltert");
  	  $(".line.filter a.tag").removeClass("aktiv");
  	  $(".line.filter a.all").addClass("aktiv");
  	  $(".line.filter select").val("all");
  	}
  	else {
  	  $("main.news section").addClass("gefiltert");
  	  $("main.news section").each(function() {
  	  	tags = $(this).data("tags").split(';');
  	  	if (jQuery.inArray(tag, tags) !== -1) {
  	  	  $(this).removeClass("gefiltert");
  	  	}
  	  });
  	  $(".line.filter a.tag").removeClass("aktiv");
  	  activeFilter.addClass("aktiv");
  	  $(".line.filter select").val(tag);
  	}
  	history.pushState(null, null, activeFilter.attr("href"));
  }
  
  $(".line.filter a.tag, main.news section .tag").on("click", function() {
  	filter($(this).data("tag"));
  	return false;
  });
  
  $(".line.filter select").on("change", function() {
  	filter($(this).val());
  	return false;
  });
  
  
});